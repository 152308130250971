import { theme as defaultTheme } from "./default";
import { theme as blueTheme } from "./blue";
import { CustomThemeConfig } from "tailwindcss/types/config";

const themes = {
  default: defaultTheme,
  blue: blueTheme,
};

export const theme = themes.blue;

const themeConfig: Partial<
  CustomThemeConfig & {
    extend: Partial<CustomThemeConfig>;
  }
> = theme;

export default themeConfig;
