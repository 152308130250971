import React from "react";
import { HotelData } from "../../context/searchProvider";
import { getApproximatePercentile } from "../helper";
import { cn } from "@/lib/utils";
import { ApproximatePercentile } from "@/generated/search.openapi";
import { Minus } from "lucide-react";

type Props = {
  hotel: HotelData;
  isHovered?: boolean;
  zIndex: number;
};

const HotelMarker: React.FC<Props> = ({ hotel, isHovered, zIndex }) => {
  const approximatePercentile = getApproximatePercentile(hotel, true);

  const price = hotel.nightlyRate
    ? Intl.NumberFormat("en-US", {}).format(hotel.nightlyRate!)
    : null;

  return (
    <div
      className={cn(
        "marker-shadow flex gap-1 rounded-full border border-black/5 px-4 py-2 text-dark",
        approximatePercentile === ApproximatePercentile.High
          ? "bg-value-good"
          : "bg-value-default",
        isHovered ? "bg-primary-gold" : ""
      )}
      style={{ zIndex }}
    >
      {price ? (
        <>
          <img
            src="/assets/dollar-black.svg"
            alt="Dollar"
            className="-ml-0.5 -mr-1 h-3 w-3"
          />
          {price}
        </>
      ) : (
        <Minus size={16} />
      )}
    </div>
  );
};

export default HotelMarker;
