import { theme } from "@/themes";
import React, { SVGAttributes } from "react";

type Props = SVGAttributes<SVGSVGElement> & {
  color?: string;
};

const Star: React.FC<Props> = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={color ?? theme.extend.colors["primary-gold"]}
        d="m10.08 1.19 1.449 4.487a.628.628 0 0 0 .599.436l4.715-.01c.25 0 .481.077.67.213.19.138.334.333.411.57.078.239.076.483.004.705-.074.222-.215.42-.417.566l-3.821 2.765a.618.618 0 0 0-.229.703l1.467 4.482a1.129 1.129 0 0 1-.412 1.272 1.132 1.132 0 0 1-.668.218c-.234 0-.466-.074-.668-.22l-3.81-2.78a.637.637 0 0 0-.37-.12.635.635 0 0 0-.37.12l-3.81 2.78a1.128 1.128 0 0 1-1.335.002 1.129 1.129 0 0 1-.412-1.272l1.464-4.482a.62.62 0 0 0-.227-.703L.49 8.157a1.134 1.134 0 0 1-.418-.566 1.134 1.134 0 0 1 .003-.705c.078-.237.222-.432.412-.57.189-.136.42-.214.67-.214l4.716.01a.628.628 0 0 0 .598-.435L7.92 1.19c.078-.238.222-.434.41-.572.19-.138.423-.216.67-.216.25 0 .481.078.671.216.189.138.333.334.41.572Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={color ?? theme.extend.colors["primary-gold"]}
          d="M0 0h18v18H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Star;
