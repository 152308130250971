import {
  ApproximatePercentile,
  HotelSummary,
  SearchSessionV2,
} from "@/generated/search.openapi";
import { createRoot } from "react-dom/client";
import { HotelData } from "../context/searchProvider";
import HotelMarker from "./mapViewComponents/HotelMarker";
import ClusterMarker from "./mapViewComponents/ClusterMarker";
import CuratedHotelMarker from "./mapViewComponents/CuratedHotelMarker";
import HotelCard from "@/components/section/card/HotelCard";
import React from "react";
import { getTimeDataFromSearchResponse } from "@/lib/utils";

export const LOCATION_PIN_Z_INDEX = 35;
export const INFO_WINDOW_Z_INDEX = 30;
export const HOVER_MARKER_Z_INDEX = 25;
export const ACTIVE_MARKER_Z_INDEX = 20;
export const MARKER_Z_INDEX = 10;
export const CLUSTER_MARKER_Z_INDEX = 5;

export type MapElementMarker = {
  marker: google.maps.Marker;
  dimmedMarker?: google.maps.Marker;
  hover: google.maps.Marker;
  zIndex: number;
};

export type MapElements = {
  infoWindow: google.maps.InfoWindow;
};

export type GetHotelMapElements = (
  hotelSummary: HotelSummary,
  searchResponse: SearchSessionV2
) => MapElements;

export const getHotelInfoBox = (
  hotel: HotelSummary,
  searchResponse: SearchSessionV2
) => {
  const infoWindow = new google.maps.InfoWindow({
    content: "",
    zIndex: INFO_WINDOW_Z_INDEX,
    pixelOffset: new google.maps.Size(0, -5),
  });
  const div = document.createElement("div");
  const root = createRoot(div);
  const { checkin, checkout } = getTimeDataFromSearchResponse(searchResponse);

  root.render(
    <HotelCard
      hotel={hotel}
      checkin={checkin}
      checkout={checkout}
      numberOfAdults={
        searchResponse?.request?.hotels?.searchOccupants?.numberOfAdults
      }
      availableTransferPartners={
        searchResponse?.results?.hotels?.availableTransferPartners
      }
      closeCard={() => {
        infoWindow.close();
      }}
      className="w-[330px]"
      innerClassName="pb-2"
    />
  );
  infoWindow.setContent(div);
  return infoWindow;
};

export const getHotelMapElements: GetHotelMapElements = (
  hotelSummary,
  searchResponse
) => {
  return {
    infoWindow: getHotelInfoBox(hotelSummary, searchResponse),
  };
};

export const getClusterRadius = (count: number) => {
  const minCount = 0;
  const maxCount = 1400;
  const minOutput = 70;
  const maxOutput = 200;
  const proportion = (count - minCount) / (maxCount - minCount);
  return minOutput + proportion * (maxOutput - minOutput);
};

export const setAdvancedCuratedHotelMarker = (
  hotel: HotelData,
  options: google.maps.marker.AdvancedMarkerElementOptions,
  isDimmed?: boolean,
  isSelected?: boolean
) => {
  const div = document.createElement("div");
  const root = createRoot(div);

  root.render(
    <CuratedHotelMarker
      hotel={hotel}
      isDimmed={isDimmed}
      isSelected={isSelected}
    />
  );

  return new google.maps.marker.AdvancedMarkerElement({
    ...options,
    content: div,
    zIndex: MARKER_Z_INDEX + (isSelected ? 1 : 0),
  });
};

export const setAdvancedHotelMarker = (
  hotel: HotelData,
  options: google.maps.marker.AdvancedMarkerElementOptions,
  isHovered?: boolean
) => {
  const div = document.createElement("div");
  const root = createRoot(div);
  const approximatePercentile = getApproximatePercentile(hotel, true);
  const zIndex =
    MARKER_Z_INDEX +
    (approximatePercentile === ApproximatePercentile.High ? 5 : 0);

  root.render(
    <HotelMarker hotel={hotel} isHovered={isHovered} zIndex={zIndex} />
  );

  return new google.maps.marker.AdvancedMarkerElement({
    ...options,
    content: div,
    zIndex,
  });
};

export const setAdvancedClusterMarker = (
  clusterNumber: number,
  options: google.maps.marker.AdvancedMarkerElementOptions
) => {
  const div = document.createElement("div");
  const root = createRoot(div);

  root.render(<ClusterMarker clusterNumber={clusterNumber} />);

  return new google.maps.marker.AdvancedMarkerElement({
    ...options,
    content: div,
    zIndex: CLUSTER_MARKER_Z_INDEX,
  });
};

export const getApproximatePercentile = (
  hotel: HotelData,
  isPoints?: boolean
) => {
  const dealScore = isPoints ? hotel.pointsDealScore : hotel.cashDealScore;
  if (dealScore === undefined) return undefined;
  switch (true) {
    case 0 < dealScore && dealScore <= 3.33:
      return ApproximatePercentile.Low;
    case 3.33 < dealScore && dealScore <= 6.66:
      return ApproximatePercentile.Average;
    case 6.66 < dealScore && dealScore <= 10:
      return ApproximatePercentile.High;
  }
};

export const getPercentileNumber = (hotel: HotelData, isPoints?: boolean) => {
  return isPoints
    ? hotel.pointsDealScore?.toFixed(1)
    : hotel.cashDealScore?.toFixed(1);
};
