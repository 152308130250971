import React from "react";
import { HotelData } from "../../context/searchProvider";
import { cn } from "@/lib/utils";

type Props = {
  hotel: HotelData;
  isDimmed?: boolean;
  isSelected?: boolean;
};

const CuratedHotelMarker: React.FC<Props> = ({
  hotel,
  isDimmed,
  isSelected,
}) => {
  const price = Intl.NumberFormat("en-US", {}).format(hotel.nightlyRate!);

  return (
    <div
      className={cn(
        "marker-shadow flex gap-1 rounded-full border border-black/5 bg-background px-4 py-2 text-primary",
        isDimmed && "opacity-50",
        isSelected && "bg-[#FABA75] text-black"
      )}
    >
      <img
        src={
          isSelected ? "/assets/dollar-black.svg" : "/assets/dollar-white.svg"
        }
        alt="Dollar"
        className="-ml-0.5 -mr-1 h-3 w-3"
      />
      {price}
    </div>
  );
};

export default CuratedHotelMarker;
