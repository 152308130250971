import React, { SVGAttributes } from "react";

type Props = SVGAttributes<SVGSVGElement> & {
  color?: string;
};

const ArrowCircleLeft: React.FC<Props> = ({ color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      {...props}
    >
      <g filter="url(#a)">
        <path
          fill={color ?? "#fff"}
          d="M19 32A13 13 0 1 0 6 19a13.014 13.014 0 0 0 13 13Zm-5.707-13.707 4-4a1 1 0 1 1 1.415 1.415L16.413 18H24a1 1 0 0 1 0 2h-7.586l2.293 2.293a1 1 0 1 1-1.415 1.415l-4-4a1.001 1.001 0 0 1 0-1.415Z"
        />
      </g>
      <defs>
        <filter
          id="a"
          width={38}
          height={38}
          x={0}
          y={0}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={3} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.55 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_713_198"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_713_198"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ArrowCircleLeft;
