"use client";

import { HotelSummary, SearchSessionV2 } from "@/generated/search.openapi";

import { distanceBetweenPointsInMiles } from "@/lib/mapUtils";

import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MapElements, getHotelMapElements } from "../[searchId]/helper";
import { LoyaltyProgramEnum } from "@/generated/email_parser.openapi";
import { getTransferPartnersMapping } from "@/lib/utils";

export type BenefitsMetrics = "Cash Only" | "Points Only";

export enum LoyaltyBrandsLegendFilters {
  Hilton_Honors = "Hilton Honors",
  Marriott_Bonvoy = "Marriott Bonvoy",
  IHG_One_Rewards = "IHG One Rewards",
  World_Of_Hyatt = "World of Hyatt",
}

export type FilterOption = {
  loyaltyBrands?: LoyaltyProgramEnum[];
  availableWithPoints?: boolean;
  selectedCostRange?: [number, number];
  costRangeLimit?: [number, number];
  selectedDistance?: [number, number];
  distanceLimit?: [number, number];
  amenities?: string[];
  starRating?: number[];
  availableAmenities?: string[];
  selectedAmenities?: string[];
  availableImageCategories?: string[];
  selectedImageCategories?: string;
  averageGuestRating?: [number, number];
  sortBy?:
    | "recommended"
    | "bestPointsValue"
    | "bestCashValue"
    | "lowestCost"
    | "closest";
};

export type HotelData = HotelSummary & {
  distance?: number;
  mapElements?: MapElements;
};

export type TransferPartnersMapping = {
  [key: string]: LoyaltyProgramEnum[];
};

type SearchContextType = {
  searchResponse?: SearchSessionV2;
  setSearchResponse?: Dispatch<SetStateAction<SearchSessionV2 | undefined>>;
  searchResponseLoading: boolean;
  setSearchResponseLoading?: Dispatch<SetStateAction<boolean>>;
  initialBounds?: google.maps.LatLngBounds;
  setInitialBounds?: Dispatch<
    SetStateAction<google.maps.LatLngBounds | undefined>
  >;
  exploreMapBounds?: google.maps.LatLngBounds;
  setExploreMapBounds?: Dispatch<
    SetStateAction<google.maps.LatLngBounds | undefined>
  >;
  setRecommendedViewport?: Dispatch<
    SetStateAction<google.maps.LatLngBounds | undefined>
  >;
  recommendedViewport?: google.maps.LatLngBounds;
  loadingScreen: boolean;
  setLoadingScreen?: Dispatch<SetStateAction<boolean>>;
  hotels: HotelData[];
  filters?: FilterOption;
  setFilters?: Dispatch<SetStateAction<FilterOption>>;
  resetFilters?: () => void;
  transferPartnersMapping?: TransferPartnersMapping;
};

const SearchContext = createContext<SearchContextType>({
  loadingScreen: true,
  searchResponseLoading: false,
  hotels: [],
});

const initialSearchFilters: FilterOption = {
  sortBy: "recommended",
  averageGuestRating: [0, 10],
};

export default function SearchProvider({ children }: PropsWithChildren) {
  const [searchResponse, setSearchResponse] = useState<SearchSessionV2>();
  const [searchResponseLoading, setSearchResponseLoading] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [initialBounds, setInitialBounds] = useState<
    google.maps.LatLngBounds | undefined
  >();
  const [exploreMapBounds, setExploreMapBounds] = useState<
    google.maps.LatLngBounds | undefined
  >();
  const [recommendedViewport, setRecommendedViewport] = useState<
    google.maps.LatLngBounds | undefined
  >();
  const [filters, setFilters] = useState<FilterOption>(initialSearchFilters);

  const hotels: HotelData[] = useMemo(() => {
    if (searchResponse?.results?.hotels?.items && searchResponse.request) {
      return (
        searchResponse.results.hotels.items?.map((hotel) => ({
          ...hotel,
          distance: distanceBetweenPointsInMiles(
            {
              lat: searchResponse.request?.hotels?.searchLocation.coordinates
                ?.latitude as number,
              lng: searchResponse.request?.hotels?.searchLocation.coordinates
                ?.longitude as number,
            },
            {
              lat: hotel.coordinates?.latitude as number,
              lng: hotel.coordinates?.longitude as number,
            }
          ),
          mapElements: getHotelMapElements(hotel, searchResponse),
        })) || []
      );
    } else {
      return [];
    }
  }, [searchResponse]);

  const filterDefaults = useMemo(() => {
    const distanceArray: number[] =
      hotels?.map((item) => item.distance ?? 0) || [];
    const costArray: number[] =
      hotels
        ?.map((item) => item.nightlyRate as number)
        .filter((item) => !!item) || [];
    const distanceLimit = [
      Math.min(...distanceArray),
      Math.max(...distanceArray),
    ] as [number, number];
    const costRangeLimit = [Math.min(...costArray), Math.max(...costArray)] as [
      number,
      number,
    ];
    return {
      availableAmenities: searchResponse?.results?.hotels?.availableAmenities,
      availableImageCategories:
        searchResponse?.results?.hotels?.availableImageCategories,
      costRangeLimit,
      distanceLimit,
    };
  }, [
    hotels,
    searchResponse?.results?.hotels?.availableAmenities,
    searchResponse?.results?.hotels?.availableImageCategories,
  ]);

  useEffect(() => {
    if (filterDefaults) {
      setFilters((prev) => {
        return {
          ...prev,
          ...filterDefaults,
        };
      });
    }
  }, [filterDefaults]);

  useEffect(() => {
    if (searchResponse?.promptResponse?.viewport && !initialBounds) {
      setInitialBounds?.(
        new google.maps.LatLngBounds(searchResponse?.promptResponse?.viewport)
      );
    }
  }, [initialBounds, searchResponse?.promptResponse?.viewport]);

  useEffect(() => {
    if (
      searchResponse?.results?.hotels?.recommendedViewport &&
      !recommendedViewport
    ) {
      setRecommendedViewport?.(
        new google.maps.LatLngBounds(
          searchResponse?.results?.hotels?.recommendedViewport
        )
      );
    }
  }, [
    recommendedViewport,
    searchResponse?.results?.hotels?.recommendedViewport,
  ]);

  const transferPartnersMapping = useMemo(
    () =>
      searchResponse?.results?.hotels?.availableTransferPartners
        ? getTransferPartnersMapping(
            searchResponse?.results?.hotels?.availableTransferPartners
          )
        : undefined,
    [searchResponse?.results?.hotels?.availableTransferPartners]
  );

  return (
    <SearchContext.Provider
      value={{
        searchResponse,
        setSearchResponse,
        searchResponseLoading,
        setSearchResponseLoading,
        initialBounds,
        setInitialBounds,
        exploreMapBounds,
        setExploreMapBounds,
        recommendedViewport,
        setRecommendedViewport,
        loadingScreen,
        setLoadingScreen,
        filters,
        setFilters,
        resetFilters: () => setFilters(initialSearchFilters),
        hotels,
        transferPartnersMapping,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

const useSearch = () => {
  const {
    searchResponse,
    setSearchResponse,
    searchResponseLoading,
    setSearchResponseLoading,
    initialBounds,
    setInitialBounds,
    exploreMapBounds,
    setExploreMapBounds,
    recommendedViewport,
    setRecommendedViewport,
    loadingScreen,
    setLoadingScreen,
    filters,
    setFilters,
    resetFilters,
    hotels,
    transferPartnersMapping,
  } = useContext(SearchContext);
  return {
    searchResponse,
    setSearchResponse,
    searchResponseLoading,
    setSearchResponseLoading,
    initialBounds,
    setInitialBounds,
    exploreMapBounds,
    setExploreMapBounds,
    recommendedViewport,
    setRecommendedViewport,
    loadingScreen,
    setLoadingScreen,
    filters,
    setFilters,
    resetFilters,
    hotels,
    transferPartnersMapping,
  };
};

export { useSearch };
