import React, { SVGAttributes } from "react";

type Props = SVGAttributes<SVGSVGElement> & {
  color?: string;
};

const Calendar: React.FC<Props> = ({ color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        fill={color ?? "#091627"}
        d="M18.584 2.5h-2.292a.209.209 0 0 1-.208-.208V.833a.833.833 0 1 0-1.667 0v3.959a.625.625 0 0 1-1.25 0V2.917a.416.416 0 0 0-.417-.417H7.542a.208.208 0 0 1-.208-.208V.833a.833.833 0 0 0-1.667 0v3.959a.625.625 0 0 1-1.25 0V2.917A.417.417 0 0 0 4 2.5H2.75a1.667 1.667 0 0 0-1.666 1.667v14.166A1.666 1.666 0 0 0 2.75 20h15.834a1.666 1.666 0 0 0 1.666-1.667V4.167A1.667 1.667 0 0 0 18.584 2.5Zm0 15.417a.417.417 0 0 1-.417.416h-15a.417.417 0 0 1-.417-.416v-10a.417.417 0 0 1 .417-.417h15a.417.417 0 0 1 .417.417v10Z"
      />
    </svg>
  );
};

export default Calendar;
