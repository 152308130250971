import React from "react";

type Props = {
  clusterNumber: number;
};

const ClusterMarker: React.FC<Props> = ({ clusterNumber }) => {
  return (
    <div className="marker-shadow rounded-full border border-black/5 bg-white px-4 py-2 text-black">
      {clusterNumber} hotels
    </div>
  );
};

export default ClusterMarker;
