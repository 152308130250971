export const HotelCardSkeleton: React.FC = () => {
  return (
    <div className="flex h-[350px] w-full animate-pulse flex-col items-start justify-start gap-3 rounded-lg p-3 shadow">
      <div className="flex h-[320px] w-full rounded bg-gray-700" />
      <div className="flex w-full gap-3">
        <div className="h-6 w-full rounded bg-gray-700" />
        <div className="h-6 w-[50px] rounded bg-gray-700" />
      </div>
      <div className="mt-auto h-10 w-52 rounded-2xl bg-gray-700" />
    </div>
  );
};

export default HotelCardSkeleton;
